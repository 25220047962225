import { Image } from '@nextui-org/react';
import { t } from 'i18next';
import React from 'react';

export default function HomeInvestors() {
  return (
    <section className="px-2 py-6 lg:p-12 !bg-jacarta-800">
      <h3 className="text-center font-display text-xl lg:text-4xl capitalize text-white">
        {t('Backed by')}
      </h3>
      <div className="container mx-auto gap-4 lg:gap-8 w-full flex-center pt-6">
        {investorsData.map((data, index) => (
          <InvestorCard key={index} data={data} />
        ))}
      </div>
    </section>
  );
}

const InvestorCard = ({ data }) => (
  <div className={`flex-center`}>
    <Image src={data.image} alt={data.name} className="md:w-[50%] md:mx-auto" />
  </div>
);

const investorsData = [
  {
    name: 'flat labs',
    image: '/images/investors/flat-labs.png',
    // description: t('flat_labs_description'),
  },
  {
    name: 'propeller',
    image: '/images/investors/propeller.png',
    // description: t('propeller_description'),
  },
  {
    name: 'march holding',
    image: '/images/investors/march-holding.png',
    // description: t('march_holding_description'),
  },
];
