import { Card, CardBody, CardHeader, cn, Image, Link } from '@nextui-org/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

function PartnersCard() {
  const router = useRouter();
  const { t } = useTranslation();
  const isAr = router.locale === 'ar-SA';

  return (
    <div className={cn('flex items-center gap-3', isAr && 'flex-row-reverse')}>
      <Image
        alt="Arab Bank"
        className="object-cover h-20 lg:h-24 rounded-md shadow-sm"
        src="/images/banks/1.png"
      />

      <Card className="bg-white rounded-md">
        <CardHeader className="justify-center text-base md:text-medium lg:text-lg font-bold dark:text-jacarta-800 pb-1.5 pt-2">
          {t('1st_place_awards')}
        </CardHeader>
        <CardBody className="pt-0 gap-2 flex-center flex-row">
          <Link
            href={
              isAr
                ? 'https://www.ammonnews.net/article/824528'
                : 'https://www.jordanpulse.com/article/3394'
            }
            isExternal
          >
            <Image
              alt="Abx"
              className="object-cover h-8 lg:h-10 rounded-none"
              src="/images/banks/2.png"
            />
          </Link>
          <Link
            href={
              isAr
                ? 'https://km.visamiddleeast.com/ar_KM/about-visa/newsroom/press-releases/prl-03062024.html'
                : 'https://km.visamiddleeast.com/en_KM/about-visa/newsroom/press-releases/prl-03062024.html'
            }
            isExternal
          >
            <Image
              alt="Arab Bank"
              className="object-cover h-8 lg:h-10 rounded-none"
              src="/images/banks/3.png"
            />
          </Link>
        </CardBody>
      </Card>
    </div>
  );
}

export default PartnersCard;
