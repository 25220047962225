import { Avatar, Image } from '@nextui-org/react';
import { t } from 'i18next';

export default function AvailableGames() {
  return (
    <section className="py-8 lg:py-20 space-y-4">
      <h2 className="text-center font-display text-xl lg:text-2xl">
        {t('available_games')}
      </h2>
      <div className="container mx-auto gap-5 lg:gap-8 w-full flex-center flex-wrap">
        {gamesData.map((game) => (
          <GameCard key={game.name} game={game} />
        ))}
      </div>
    </section>
  );
}

const GameCard = ({ game }) => {
  return (
    <>
      <Image
        src={game.imageUrl}
        alt={game.name}
        className="h-[100px] object-cover hidden md:flex shadow-md"
      />
      <Avatar
        src={game.imageUrl}
        alt={game.name}
        className="h-28 w-28 object-cover md:hidden shadow-md"
      />
    </>
  );
};

const gamesData = [
  {
    name: 'League of Legends',
    imageUrl: '/images/available-games/lol.jpg',
  },
  {
    name: 'Valorant',
    imageUrl: '/images/available-games/valorant.jpg',
  },
  {
    name: 'Dota 2',
    imageUrl: '/images/available-games/dota2.jpg',
  },
  {
    name: 'Fortnite',
    imageUrl: '/images/available-games/fortnite.jpg',
  },
  {
    name: 'Brawl Stars',
    imageUrl: '/images/available-games/brawlstars.jpg',
  },
];
