import { Card, CardFooter, Image } from '@nextui-org/react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import LoginButton from '../navbar/LoginButton';
import PartnersCard from './common/PartnersCard';
import PlayNowButton from './common/PlayNowButton';

const Hero = () => {
  const { status } = useSession();
  const { locale } = useRouter();
  const { t } = useTranslation();

  const isAr = locale === 'ar-SA';

  return (
    <section className="relative py-14 lg:py-20 ">
      <div
        className={`container mx-auto flex gap-6 md:gap-2 lg:gap-3 justify-between w-full xl:gap-10 flex-col-reverse md:flex-row items-center `}
      >
        <Card radius="lg" className="border-none w-full md:w-1/2 h-fit pt-0">
          <Image
            alt="hero animated image"
            className="object-cover w-full "
            src="/images/hero/animated-hero-image.webp"
          />
          <CardFooter className="overflow-hidden  absolute bottom-[7%] w-full shadow-small z-10 flex-col rounded-none bg-sponsor-bg pt-0.5 pb-0">
            <h3 className="text-md lg:text-xl font-bold text-white ">
              {t('strategic_partner')}
            </h3>
            <div
              className={`flex-center w-full ${isAr ? 'flex-row-reverse' : ''}`}
            >
              <Image
                alt="zain logo"
                className="object-cover h-16 md:h-20 rounded-none"
                src="/images/sponsors/1.png"
                removeWrapper
              />
            </div>
          </CardFooter>
        </Card>
        <div className="w-full md:w-1/2 flex flex-col items-center gap-5 z-10">
          <h1 className="text-2xl lg:text-4xl xl:text-6xl flex-center flex-col uppercase font-bold font-display text-balance">
            <span>{t('play_win')}</span>
            <span>{t('compete_everyday')}</span>
          </h1>
          <p className="dark:text-jacarta-200 text-center text-medium lg:text-lg ">
            {t('home_hero_p')}
          </p>

          <div className="flex-center mb-5 md:mb-0">
            <LoginButton classNames="lg:w-74 xl:w-80" />
            {status == 'authenticated' && <PlayNowButton />}
          </div>

          <PartnersCard />
        </div>
      </div>
    </section>
  );
};

export default Hero;
