import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeLoader from '../components/dashboard/UserDashboard/HomeLoader';
import AvailableGames from '../components/home/AvailableGames';
import Extras from '../components/home/Extras';
import Hero from '../components/home/hero';
import HomeInvestors from '../components/home/HomeInvestors';
import HomeLeadBoard from '../components/home/HomeLeadBoard';
import PaymentMethods from '../components/home/PaymentMethods';
import ResultTracking from '../components/home/ResultTracking';
import UnlimitedTours from '../components/home/UnlimitedTours';
import Meta from '../components/Meta';
import { useScrollPositionStore } from '../store/useScrollPosition';

export default function Home() {
  const { t } = useTranslation();
  const { status } = useSession();

  const { scrollPosition, setPosition } = useScrollPositionStore();

  useEffect(() => {
    window.scrollTo(0, scrollPosition);
    const handleScrollPos = () => {
      setPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScrollPos);
    return () => {
      window.removeEventListener('scroll', handleScrollPos);
    };
  });

  if (status === 'loading') return <HomeLoader />;

  return (
    <>
      <Meta title={t('Gamerg_Home_Title')} />

      <div className="even:*:bg-light-base  even:*:dark:bg-jacarta-800">
        <Hero />
        <AvailableGames />
        <UnlimitedTours />
        <Extras />
        <PaymentMethods />
        <HomeLeadBoard />
        <ResultTracking />
        <HomeInvestors />
      </div>
    </>
  );
}
