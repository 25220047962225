import { Image, Progress } from '@nextui-org/react';
import React from 'react';

function HomeLoader() {
  return (
    <div
      className="absolute top-0 left-0 w-screen h-screen bg-cover bg-center z-50 flex-center"
      style={{
        backgroundImage: `url('/images/gradient_dark.jpg')`,
      }}
    >
      <div className="flex flex-col gap-4 items-center">
        <Image
          disableSkeleton
          src="/images/gamelogo/small/gamerg.webp"
          alt="Gamerg.gg logo"
          className="h-56"
        />
        <Progress
          // size="sm"

          isIndeterminate
          aria-label="Loading..."
          className="flex-1"
        />
      </div>
    </div>
  );
}

export default HomeLoader;
